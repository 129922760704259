<template>
    <div class="action-schedule">
        <div class="title f-bold" v-html="title" />
        <div class="body" v-html="body" />
        <div class="body"><b>요청메시지</b></div>
        <div class="content">
            {{ content.reject_message }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActionReschedule',
    props: ['message'],
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        title() {
            if (this.message.mtype === 'schedule-change') {
                return '일정 변경을 요청했어요'
            } else if (this.message.mtype === 'schedule-restart-a') {
                return '일정 변경이 요청되었어요'
            }
            return ''
        },
        body() {
            if (this.message.mtype === 'schedule-change') {
                return `${this.content.name} / ${this.$options.filters.asAge(this.content.birthday)} / ${
                    this.content.job
                } 님에게 일정 변경을 요청했어요 `
            } else if (this.message.mtype === 'schedule-restart-a') {
                return `${this.content.name} / ${this.$options.filters.asAge(this.content.birthday)} / ${
                    this.content.job
                } 님이 일정 변경을 요청했어요`
            }
            return ''
        },
    },
}
</script>
<style scoped lang="scss">
.content {
    background-color: $grey-03;
    margin: 0 16px 16px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 10px;
}
</style>
